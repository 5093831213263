export const API_BASE_URL = {
  WALLPAPERS: '/wallpapers/api',
  TAGS: '/tags/api'
};

export const API_ENDPOINTS = {
  TAGS: '/list',
  WALLPAPERS: '/list',
  DOWNLOAD: (id: number) => `/wallpapers/download/${id}`
};

export const fetchConfig: RequestInit = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
}; 