import React, { useState, useEffect, useRef } from 'react';
import Masonry from 'react-masonry-css';
import './App.css';
import { API_BASE_URL, API_ENDPOINTS, fetchConfig } from './config';
import ImageModal from './components/ImageModal';

type WallpaperCategory = string;

interface Category {
  id: number;
  name: string;
  description: string;
  createTime: string;
  updateTime: string;
}

interface DisplayCategory {
  id: string;
  tagId: number;
  name: string;
  icon: string;
  description: string;
}

interface Wallpaper {
  id: number;
  title: string;
  s3Url: string;
  thumbnailUrl: string;
  description: string;
  tags: string[];
  createTime: string;
  updateTime: string;
}

interface WallpaperResponse {
  content: Wallpaper[];
  pageable: {
    pageNumber: number;
    pageSize: number;
  };
  totalPages: number;
}

function App() {
  const [selectedCategory, setSelectedCategory] = useState<WallpaperCategory>('all');
  const [categories, setCategories] = useState<DisplayCategory[]>([]);
  const [wallpapers, setWallpapers] = useState<Wallpaper[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastWallpaperRef = useRef<HTMLDivElement | null>(null);
  const wallpaperCache = useRef<Map<string, Wallpaper[]>>(new Map());
  const [selectedWallpaper, setSelectedWallpaper] = useState<Wallpaper | null>(null);

  /**
   * 获取分类图标
   * @param categoryName - 分类名称
   * @returns 对应的emoji图标
   */
  const getIconForCategory = (categoryName: string): string => {
    const icons: { [key: string]: string[] } = {
      'All': ['🌟', '✨', '💫', '⭐'],
      'Nature': ['🌿', '🌱', '🌲', '🍀', '🌺', '🌸'],
      'City': ['🌆', '🌃', '🌉', '🏙️', '🌇'],
      'Animals': ['🦁', '🐯', '🐼', '🦊', '🐨', '🦄'],
      'Abstract': ['🎨', '🎭', '🖼️', '🔮'],
      'Space': ['🚀', '🌌', '🌠', '🌍', '🌘'],
      'Architecture': ['🏛️', '🏰', '🗼', '⛩️', '🕌'],
      'Minimalist': ['⚪', '⚫', '◽', '◾', '▫️'],
      'Ocean': ['🌊', '🐋', '🐠', '🐚', '🏖️'],
      'Mountains': ['🗻', '⛰️', '🏔️', '🌄'],
      'Forest': ['🌲', '🌳', '🍂', '🍁', '🌿'],
      'Night': ['🌙', '🌚', '🌕', '🌑', '✨'],
      'Flowers': ['🌸', '🌺', '🌹', '', '💐'],
      'Technology': ['💻', '📱', '⌚', '🖥️', '🤖'],
      'Art': ['🎨', '🖼️', '🎭', '🎪', '🎯'],
      'Vintage': ['📷', '📻', '⏰', '📽️', '💽'],
      'Food': ['🍜', '🍣', '🍱', '🍕', '🍔'],
      'Travel': ['✈️', '🗺️', '🧭', '🎡', '🎢'],
      'Sports': ['⚽', '🏀', '🎾', '⚾', '🏈'],
      'Cars': ['🚗', '🏎️', '🚙', '🚓', '🚘'],
      'Music': ['🎵', '🎶', '🎹', '🎸', '🎺'],
      'Default': ['🌟', '✨', '💫', '⭐', '🔮']
    };

    // 获取分类对应的图标数组，如果没有则使用默认图标
    const categoryIcons = icons[categoryName] || icons['Default'];
    
    // 随机选择一个图标
    const randomIndex = Math.floor(Math.random() * categoryIcons.length);
    return categoryIcons[randomIndex];
  };

  /**
   * 获取分类列表
   * @returns Promise<void>
   */
  const fetchCategories = async (): Promise<void> => {
    try {
      console.log('开始获取分类数据');
      const response = await fetch(`${API_BASE_URL.TAGS}${API_ENDPOINTS.TAGS}`, fetchConfig);
      if (!response.ok) {
        throw new Error('获取分类失败');
      }
      const data: Category[] = await response.json();
      console.log('获取到的分类数据:', data);

      const allCategories: DisplayCategory[] = [
        {
          id: 'all',
          tagId: 0,
          name: 'All',
          icon: getIconForCategory('All'),
          description: '所有壁纸'
        },
        ...data.map(category => ({
          id: category.name.toLowerCase(),
          tagId: category.id,
          name: category.name,
          icon: getIconForCategory(category.name),
          description: category.description
        }))
      ];
      
      setCategories(allCategories);
    } catch (error) {
      console.error('获取分类失败:', error);
      setCategories([
        { id: 'all', tagId: 0, name: 'All', icon: '🌟', description: '所有壁纸' },
      ]);
    }
  };

  /**
   * 获取壁纸列表
   * @param category - 分类ID
   * @param pageNum - 页码
   * @returns Promise<void>
   */
  const fetchWallpapers = async (category: string, pageNum: number): Promise<void> => {
    try {
      setLoading(true);
      const cacheKey = `${category}-${pageNum}`;
      const cachedData = wallpaperCache.current.get(cacheKey);

      if (cachedData) {
        if (pageNum === 1) {
          setWallpapers(cachedData);
        } else {
          setWallpapers(prev => [...prev, ...cachedData]);
        }
        setLoading(false);
        return;
      }

      let url = `${API_BASE_URL.WALLPAPERS}${API_ENDPOINTS.WALLPAPERS}?page=${pageNum}&size=20`;
      
      if (category !== 'all') {
        const selectedCategory = categories.find(
          cat => cat.id.toLowerCase() === category.toLowerCase()
        );
        if (selectedCategory?.tagId) {
          url += `&tagId=${selectedCategory.tagId}`;
        }
      }
      
      const response = await fetch(url, fetchConfig);
      if (!response.ok) {
        throw new Error(`获取壁纸失败: ${response.status}`);
      }

      const data: WallpaperResponse = await response.json();
      wallpaperCache.current.set(cacheKey, data.content);
      
      if (pageNum === 1) {
        setWallpapers(data.content);
      } else {
        setWallpapers(prev => [...prev, ...data.content]);
      }
      
      setHasMore(pageNum < data.totalPages);
    } catch (error) {
      console.error('获取壁纸失败:', error);
      setWallpapers([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // 优化滚动加载逻辑
  useEffect(() => {
    if (loading || !hasMore) return;

    const handleScroll = () => {
      // 检查是否接近底部
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      // 当距离底部200px时开始加载下一页
      if (scrollHeight - scrollTop - clientHeight < 200 && !loading && hasMore) {
        setPage(prev => prev + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]);

  // 切换分类时重置状态
  useEffect(() => {
    console.log('分类切换为:', selectedCategory);
    console.log('当前所有分类:', categories);
    setWallpapers([]);
    setPage(1);        // 重置为第一页
    setHasMore(true);
    wallpaperCache.current.clear();
    window.scrollTo(0, 0);
  }, [selectedCategory]);

  // 加载壁纸
  useEffect(() => {
    if (page === 0 || hasMore) {
      fetchWallpapers(selectedCategory, page);
    }
  }, [selectedCategory, page]);

  // 初始加载分类
  useEffect(() => {
    fetchCategories();
  }, []);

  // 切换主题
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleWallpaperClick = (wallpaper: Wallpaper) => {
    setSelectedWallpaper(wallpaper);
  };

  const handleCloseModal = () => {
    setSelectedWallpaper(null);
  };

  // 记录下载（异步，不等待结果）
  const recordDownload = (wallpaperId: number) => {
    console.log('记录下载:', wallpaperId);
    // 使用 Promise 包装但不等待结果
    fetch(API_ENDPOINTS.DOWNLOAD(wallpaperId), {
      method: 'GET',
      ...fetchConfig
    })
      .then(response => {
        if (!response.ok) {
          console.error('记录下载失败:', response.status);
        } else {
          console.log('记录下载成功');
        }
      })
      .catch(error => {
        console.error('记录下载出错:', error);
      });
  };

  const handleDownload = (wallpaper: Wallpaper, e: React.MouseEvent) => {
    e.stopPropagation();
    
    // 执行下载
    const link = document.createElement('a');
    link.href = wallpaper.s3Url;
    link.download = `${wallpaper.title}-${Date.now()}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // 异步记录下载
    recordDownload(wallpaper.id);
  };

  // 为模态框创建单独的下载处理函数
  const handleModalDownload = () => {
    if (!selectedWallpaper) return;
    
    // 执行下载
    const link = document.createElement('a');
    link.href = selectedWallpaper.s3Url;
    link.download = `${selectedWallpaper.title}-${Date.now()}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // 异步记录下载
    recordDownload(selectedWallpaper.id);
  };

  // 调整瀑布流的断点设置，增加列数
  const breakpointColumnsObj = {
    default: 8,    // 默认显示8列
    1600: 7,      // <=1600px 显示7列
    1400: 6,      // <=1400px 显示6列
    1100: 5,      // <=1100px 显示5列
    900: 4,       // <=900px 显示4列
    700: 3,       // <=700px 显示3列
    500: 2        // <=500px 显示2列
  };

  // 在标签点击处理函数中添加日志
  const handleCategoryClick = (categoryId: string) => {
    setWallpapers([]);
    setPage(1);
    setHasMore(true);
    wallpaperCache.current.clear();
    setSelectedCategory(categoryId);
  };

  useEffect(() => {
    fetchWallpapers(selectedCategory, 1);
  }, [selectedCategory]);

  return (
    <div className={`App ${isDarkMode ? 'dark' : 'light'}`}>
      <header className="App-header">
        <h1 className="app-title">HD Wallpapers</h1>
        <nav className="category-nav">
          <div className="category-scroll">
            {categories.map(cat => (
              <button
                key={cat.id}
                className={`category-btn ${selectedCategory === cat.id ? 'active' : ''}`}
                onClick={() => handleCategoryClick(cat.id)}
                title={cat.description}
              >
                <span className="category-icon">{cat.icon}</span>
                {cat.name}
              </button>
            ))}
          </div>
        </nav>
      </header>
      <main className="wallpaper-grid">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {wallpapers.map((wallpaper) => (
            <img 
              key={wallpaper.id}
              src={wallpaper.thumbnailUrl}
              alt={wallpaper.title}
              loading="lazy"
              decoding="async"
              onClick={() => handleWallpaperClick(wallpaper)}
              onLoad={(e) => {
                (e.target as HTMLImageElement).classList.add('loaded');
              }}
              className="wallpaper-image"
            />
          ))}
        </Masonry>
        {loading && (
          <div className="loading-container">
            <div className="loading-spinner" />
            <p>Loading...</p>
          </div>
        )}
      </main>
      <button 
        className="theme-toggle-btn" 
        onClick={toggleTheme}
        title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
      >
        {isDarkMode ? '🌞' : '🌙'}
      </button>
      
      <ImageModal 
        wallpaper={selectedWallpaper}
        onClose={handleCloseModal}
        onDownload={handleModalDownload}
      />
    </div>
  );
}

export default App; 