import React from 'react';
import './ImageModal.css';

interface ImageModalProps {
  wallpaper: {
    id: number;
    title: string;
    s3Url: string;
    thumbnailUrl: string;
    description: string;
  } | null;
  onClose: () => void;
  onDownload: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ wallpaper, onClose, onDownload }) => {
  if (!wallpaper) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <div className="modal-image-container">
          <img src={wallpaper.s3Url} alt={wallpaper.title} />
          <button className="modal-download-button" onClick={onDownload}>
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal; 